// colors
$primary-brighter-blue: #06f;
$primary-bright-blue: #0052cc;
$primary-dark-blue: #00388e;
$primary-darkest-blue: #233b5f;
$background-pale-gray: #edeff6;
$background-orange: #fdaa29;
$text-dark: #00233d;
$text-gray-cold: #405a6e;
$text-gray-warm: #707070;
$semantics-success: #007d1d;
$semantics-success-pale: #def3e3;
$semantics-warning: #954700;
$semantics-warning-pale: #f7e1ce;
$semantics-destructive: #c80000;
$semantics-destructive-pale: #f0d8d8;
$very-dark-blue: #172b4d;
$dark-desaturated-blue: #42526e;
$gray-1: #e0e2eb;
$gray-2: #dfe1e6;
$gray-3: #caccd3;
$off-white: #e3eeff;
$white: #fff;
$black: #000;

// spacing
$border-radius: 1.2rem;
