.pills {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 1.3rem 1.2rem;
    margin-top: 0.5rem;
    font-weight: bold;
    font-size: 1.6rem;
    font-family: Nunito, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    border-radius: $border-radius;

    &--warning {
        background: #f7e1ce;
        color: #954700;
    }

    &--success {
        background: #def3e3;
        color: #007d1d;
    }

    &--error {
        background: #f0d8d8;
        color: #c80000;
    }

    &--general {
        background: #edeff6;
        color: #00233d;
    }

    &--icon {
        margin-right: 1rem;
        font-size: 2.2rem;
        width: 100%;

        &-wrapper {
            display: flex;
            align-items: center;
        }
    }

    &--no-background {
        background: none;
    }
}
