.resource-page {
  display: flex;
  flex-flow: column nowrap;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  &__content {
    display: flex;
    flex-flow: row nowrap;
    margin: 4rem;

    @media only screen and (max-width: 767px) {
      flex-flow: column nowrap;
      margin: 1rem;
    }
  }

  &__left {
    flex-flow: column nowrap;
    flex: 2;
    margin: 2rem;
  }

  &__info {
    margin-top: 4rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
  }

  &__table {
    flex: 1;
  }

  &__heading {
    display: flex;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &__heading-secondary {
    font-size: 2.3rem;
    font-weight: 700;
  }

  &__description {
    font-size: 1.7rem;
    line-height: 1.6;
  }

  &__services--wrapper,
  &__highlights--wrapper,
  &__accessibility--wrapper {
    margin-top: 20px;
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
  }

  &__pills {
    display: flex;
    flex-flow: row wrap;
    gap: 4rem;
  }
}