.FilterForm {
  width: 425px;
  padding: 60px 50px;

  @media only screen and (max-width: 600px) {
    padding: 0;
    padding-bottom: 10%;
    width: 100%;
  }

  @media (min-width: 1440px) and (min-height: 1505px) {
    width: 440px;
    padding: 40px 50px;
  }

  &__flexWrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 6rem;

    Button {
      max-width: max-content;
      margin: 2%;
      padding: 1.6rem 1.8rem;
      font-size: 18px;
      font-weight: 600;
    }

    @media (min-width: 1440px) and (min-height: 1505px) {
      margin-top: 6rem;
    }

    @media only screen and (max-width: 600px) {
      background: #edeff6;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      width: 100%;

      Button {
        padding: 2rem 1.8rem;
      }
    }
  }

  .filterResource {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    padding-bottom: 20px;
    color: #00233d;
  }

  .clearFilters {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #0052cc;
    cursor: pointer;
  }

  .andTags {
    margin-top: 2rem;

    input {
      margin-top: 2rem;
      margin-right: 12px;
      margin-left: 5px;
      cursor: pointer;

      @media only screen and (max-width: 600px) {
        margin-left: 4rem;
      }
    }

    span {
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
    }
  }
}

.FilterPanel {
  &__FormSection {
    column-count: 2;
    overflow-wrap: break-word;

    @media only screen and (max-width: 600px) {
      column-count: 1;
    }

    @media (min-width: 1440px) and (min-height: 1505px) {
      padding: 16px 0px;
    }

    h1 {
      column-span: all;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #00233d;
      margin-bottom: 10px;
      margin-top: 20px;

      @media (min-width: 1440px) and (min-height: 1505px) {
        margin-top: 25px;
        margin-bottom: 14px;
      }

      @media only screen and (max-width: 600px) {
        padding: 0 5rem;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }
    }

    p {
      @media only screen and (max-width: 600px) {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        padding: 0 5rem;
        color: #000000;
      }
    }
  }

  &__FormItem {
    padding: 6px 4px;
    align-items: center;
    font-family: Nunito;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;

    label {
      display: flex;
      align-items: baseline;
      width: 200px;
      padding: 18px 0px 0px 0px;

      @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 0;
      }
    }

    @media (min-width: 1440px) and (min-height: 1505px) {
      line-height: 4rem;
    }

    input {
      margin-right: 12px;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      flex: 0.9;
      flex-wrap: wrap;
      line-height: 22px;
      cursor: pointer;
    }
  }

  &__FilterDistance {
    display: grid;
    row-gap: 20px;
    row-gap: 33px;
    overflow-wrap: break-word;

    input {
      margin-right: 12px;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      flex: 0.9;
      flex-wrap: wrap;
      line-height: 22px;
      cursor: pointer;
    }

    h1 {
      column-span: all;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #00233d;
      margin-top: 30px;
    }

    @media (min-width: 1440px) and (min-height: 1505px) {
      row-gap: 32px;
      margin-top: 28px;
    }
  }

  &__FilterVerifier {
    display: grid;
    row-gap: 20px;
    row-gap: 33px;
    overflow-wrap: break-word;

    input {
      margin-right: 12px;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      flex: 0.9;
      flex-wrap: wrap;
      line-height: 22px;
      cursor: pointer;
    }

    h1 {
      column-span: all;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #00233d;
      margin-top: 30px;
    }

    @media (min-width: 1440px) and (min-height: 1505px) {
      row-gap: 32px;
      margin-top: 28px;
    }
  }
}