.header-landing {
  padding: 6rem 5rem;
  width: 100%;
  background-image: url('../resources/map-header.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  flex: auto;
  box-sizing: border-box;

  &__desktop {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
  }

  &__btnwrapper {
    align-self: right;
    display: flex;
    gap: 2rem;
    font-size: 2rem;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  &__signout {
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    color: white;
  }

  &__head {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__language {
    visibility: hidden;
  }

  &__description {
    font-size: 2rem;

    @media only screen and (max-width: 767px) {
      padding: 0 4%;
    }
  }

  &__search-wrapper {
    width: 75%;
    margin: 5rem 8rem;
  }

  &__mobile {
    display: none;
  }

  &__browse {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  &__browse-text {
    cursor: pointer;
    text-decoration: underline;
  }

  @media only screen and (width <=37.5em) {
    padding: 1.6rem 0.9rem;

    &__search-wrapper {
      display: none;
    }

    &__mobile {
      margin-top: 1.3rem;
      display: none;
    }
  }
}

.header-search {
  width: 100%;
  padding: 2.4rem 5rem;
  background-color: $primary-darkest-blue;
  color: #fff;

  &__language {
    visibility: hidden;
  }

  &__desktop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btnwrapper {
    align-self: right;
    display: flex;
    gap: 2rem;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  &__search-wrapper {
    width: 70%;
    margin: 0 5rem;
  }

  &__mobile {
    display: none;
    padding: 0 4%;
  }

  &__signout {
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    color: white;
  }

  @media only screen and (width <=37.5em) {
    padding: 1.6rem 0.9rem;

    &__search-wrapper {
      display: none;
    }

    &__mobile {
      margin-top: 1.3rem;
      display: block;
      min-width: 100%;
      padding: 0 4%;
    }
  }
}

.header-dashboard {
  width: 100%;
  padding: 2.4rem 5rem;
  background-color: $primary-darkest-blue;
  color: #fff;

  &__language {
    visibility: hidden;
  }

  &__desktop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__signout {
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    color: white;
  }

  &__btnwrapper {
    align-self: right;
    display: flex;
    gap: 2rem;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  @media only screen and (width <=37.5em) {
    padding: 1.6rem 0.9rem;
  }
}