.search-page {
    &__buttons-wrapper--secondary {
        display: flex;
        align-items: center;
        margin: 1rem;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1000;
        background: rgb(255, 255, 255);
        border-radius: 10px;
        box-shadow: #00233d 0 0 0 2px inset;

        button {
            box-shadow: none;
            padding: 1.5rem 0.8rem;
            @media only screen and (max-width: 600px) {
                width: 100%;
                justify-content: center;
                text-align: center;
                font-size: 1.6rem;
                // padding: 1.3rem 0.5rem;
                margin: 0;
            }
        }
    }
    &__buttons-wrapper--secondary:hover {
        opacity: 0.7;
    }
    &__icon {
        color: $text-gray-cold;
        font-size: 1.7rem;
        display: flex !important;
        margin-left: 0.8rem;
    }
    a {
        color: #0e0e0e;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 700;
        cursor: pointer;
    }
}
.leaflet-pane {
    margin-top: 3.3rem;
}
