.cardResponsive {
    display: flex;
    outline-offset: -1px;
    border-radius: 1.2rem;
    padding: 2.8rem 2rem;
    width: 100%;
    &__active {
        outline: 2px solid $primary-brighter-blue;
        outline-offset: -1px;
        box-shadow: 0 4px 2.2rem 0 rgb(0 0 0 / 12%);
        cursor: pointer;
    }
    &__left {
        display: flex;
        flex-flow: column nowrap;
        margin-right: auto;

        & > *:not(:last-child) {
            margin-bottom: 1.7rem;
        }
    }
    &__header {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.7rem;
        color: #00233d;
        width: 100%;
    }
    &__address {
        max-width: 100%;
        width: fit-content;
        a {
            text-decoration: underline;
            color: rgba(0, 35, 61, 0.75) !important;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.4rem;
        }
    }
    &__distance {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.4rem;
        color: #707070;
    }
    &__website {
        color: $primary-brighter-blue;
        font-size: 1.6rem;
        text-decoration: underline;
        font-weight: 600;
    }
    &__phone {
        font-size: 1.6rem;
        font-weight: 600;
    }
    &__website &__phone {
        display: flex;
        align-items: center;
    }
    &__icon {
        margin-right: 0.5rem;
        color: $text-dark;
    }
    &__hours-pill {
        // top: 2.8rem;
        // right: 3.2rem;
    }
    &__button-wrapper--primary {
        display: flex;
        justify-content: center;
        button {
            width: 26rem;
            justify-content: center;
        }
    }
}
.request-popup .leaflet-popup-content {
    margin: 0;
}
