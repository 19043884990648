.map {
    position: relative;
    &__note {
        position: absolute;
        top: -8%;
    }
    &__input {
        input {
            position: absolute;
            width: 80%;
            top: 3%;
            left: 10%;
            z-index: 1000;
            padding: 5px;
            border-radius: 4px;
            @media only screen and (max-width: 600px) {
                left: 20%;
                width: 70%;
            }
        }
    }
    &__suggestions {
        position: absolute;
        z-index: 10000;
        background-color: $white;
        height: max-content;
        width: 80%;
        top: 11%;
        left: 10%;
        border: solid $gray-1 2px;
        border-top: none;
        border-radius: 0 0 1.2rem 1.2rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &::after {
            content: '';
            z-index: -1;
        }
        @media only screen and (max-width: 600px) {
            left: 20%;
            width: 70%;
        }
    }
}
