.button {
  font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: $border-radius;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 2rem;
  font-size: 1.8rem;
  padding: 1.7rem 2rem;

  &__icon {
    margin-right: "0.8rem";
    font-size: "2rem";
  }

  &--primary {
    color: white;
    background-color: $primary-bright-blue;
  }

  &--secondary {
    color: $text-dark;
    background-color: transparent;
    box-shadow: $text-dark 0 0 0 2px inset;

    &Google,
    &Microsoft {
      background-color: #fff;
      /* White background */
      color: #00233d;
      /* Gray text */
      border: 2px solid #00233d;
      /* Light gray border */
      box-shadow: none;
      /* No shadow */
      align-items: center;
      /* Align items for flex container */
      display: flex;
      /* Flex display to align icon and text */
      justify-content: center;
      /* Center button contents */
      width: 100%;
      margin: 0;
      padding: 1.2rem 1rem;
    }
  }

  &--link {
    color: $primary-bright-blue;
    background-color: transparent;
    padding: 1rem;
  }

  &--small {
    line-height: 1rem;
    font-size: 1.8rem;
  }

  &--medium {
    line-height: 2rem;
    font-size: 1.8rem;
  }

  &--large {
    line-height: 3rem;
    font-size: 2rem;
  }

  @media only screen and (width <=600px) {
    width: 80%;
    justify-content: center;
    text-align: center;
    font-size: 1.6rem;
    padding: 1.3rem 0.5rem;
    margin: 0 0.5rem;
  }
}